import StoreItemsModule from "@/config/store/StoreItemsModule"

import { withLoading } from "@/config/store/StoreItemsModule/helpers"
import { map } from "lodash-es"

import extractMutations from "./mutations"
import extractActions from "./actions"

const BASE_URI = "/notifications"

const mapFilters = ({ read_status, reference_items, otas, start_date, end_date, objects, car_classes }) => ({
  read_status: map(read_status, "value"),
  reference_ids: map(reference_items, "id"),
  car_class_ids: map(car_classes, "id"),
  ota_ids: map(otas, "id"),
  start_date,
  end_date,
  objects: map(objects, "value")
})

const store = new StoreItemsModule({
  baseURI: BASE_URI,
  presetActions: ["fetch_post"],
  withFilters: {
    read_status: [],
    reference_items: [],
    otas: [],
    car_classes: [],
    objects: [],
    start_date: undefined,
    end_date: undefined
  },
  withPagination: true,
  withSorting: {
    type: "asc",
    field: "read"
  },
  mapFilters
})

store.mergeState({
  itemsGrouped: [],
  readStatuses: [],
  objects: [],
  unreadItemsCount: 0
})

const mutations = extractMutations()
store.mergeMutations(mutations)

const actions = extractActions({ baseURI: BASE_URI })
store.mergeActions(actions, withLoading)

export default store
