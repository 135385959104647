import { api } from "@/config"
import { reject, includes, map } from "lodash-es"

const buildParams = (filters, pagination) => {
  const { start_date, end_date, read_status, reference_items, otas, objects, car_classes } = filters
  return {
    filters: {
      read_status: map(read_status, "value"),
      objects: map(objects, "value"),
      reference_ids: reference_items && reference_items.length > 0 ? map(reference_items, "id") : undefined,
      ota_ids: otas && otas.length > 0 ? map(otas, "id") : undefined,
      car_class_ids: car_classes && car_classes.length > 0 ? map(car_classes, "id") : undefined,
      start_date,
      end_date
    },
    pagination
  }
}

export default ({ baseURI }) => ({
  FETCH_UNREAD_ITEMS_COUNT: async ({ commit }) => {
    const { data } = await api.get(`${baseURI}/unread_count`)
    commit("SET_UNREAD_ITEMS_COUNT", data.data)
  },

  FETCH_ITEMS_GROUPED: async ({ commit }, params = {}) => {
    const { data } = await api.get(`${baseURI}/grouped`, { params })
    commit("SET_ITEMS_GROUPED", data.data)
  },

  SWITCH_READ_ITEMS: async ({ state, commit }, { ids, discard = false, read = true }) => {
    const { items, filters, pagination } = state
    const params = { ids, read, ...buildParams(filters, pagination) }
    const response = await api.post(`${baseURI}/switch_read_status`, params)
    const { items: updatedItems, meta } = response.data.data

    if (discard) {
      const newItems = reject(items, ({ id }) => includes(ids, id))
      commit("SET_ITEMS", newItems)
    } else {
      commit("SET_ITEMS", updatedItems)
      commit("SET_PAGINATION_DATA", meta.pagination)
    }
  },

  DELETE_ITEMS: async ({ dispatch }, { ids, reload = false }) => {
    const params = { ids }
    await api.delete(`${baseURI}/destroy_by_ids`, { params })
    if (reload) {
      dispatch("FETCH_ITEMS")
    }
  }
})
